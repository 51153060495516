<div>
  <div #privacy_usa_container_top></div>
  <div>
    <p class="mt-4 text-lg font-bold">Paidiem Technologies Inc. USA Privacy Policy</p>
  </div>
  <div>
    <p>
      At Paidiem Technologies Inc. ("Paidiem," "we," "us," or "our"), we value your privacy. This Privacy Policy explains how we collect,
      use, disclose, and protect your personal information when you use our website at www.paidiem.com ("Website"), our mobile application
      ("App", together with the Website, the “Platform”), and our services. This Policy is intended to comply with U.S. privacy laws,
      including the the Gramm-Leach-Bliley Act (GLBA) and other relevant regulations.
    </p>
    <p>
      <span>Topics:</span>
    </p>
    <ul>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_usa_section_1)"
          >Who Receives This Privacy Notice?</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_usa_section_2)"
          >Categories of Information We Collect</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_usa_section_3)"
          >We Collect Your Data When:</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_usa_section_4)"
          >How We Use Your Information</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_usa_section_5)"
          >Disclosure of Information to Third Parties</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_usa_section_6)">
          Categories of Information Disclosed</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_usa_section_7)"
          >Your Rights and Opt-Out Options</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_usa_section_8)"
          >Protection of Nonpublic Personal Information (NPI)</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_usa_section_9)"
          >Governing Law</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_usa_section_10)"
          >Changes to This Privacy Policy</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_usa_section_11)"
          >Delivery of Privacy Notices</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_usa_section_12)"
          >Contact Information</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_usa_section_13)"
          >Summary of Notice Requirements</a
        >
      </li>
    </ul>
  </div>

  <div #privacy_usa_section_1>
    <p class="mt-4 text-lg font-bold">1. Who Receives This Privacy Notice?</p>
    <p data-cy="textPrivacyUsaWhoReceives">This Privacy Policy applies to:</p>
    <ol>
      <li>
        Platform Users: Individuals accessing and using the website located at www.paidiem.com (“Website”) or mobile app (collectively,
        “Platform”).
      </li>
      <li>
        Individuals whose data may be collected and provided to us by their employer, staffing agency, or payment processor in order to use
        or provide Paidiem's services.
      </li>
      <li>
        Potential Users: Individuals interacting with our Platform or services (e.g. through inquiries or marketing channels) in order to
        explore our services.
      </li>
    </ol>
    <p>
      All individuals whose personal data is collected—whether directly or indirectly—will receive notice of this Privacy Policy either at
      the time of data collection or at the time our services are provided, as applicable.
    </p>
    <p>
      The Paidiem platform and services are not intended for individuals below 18 years of age and we do not intend to collect data related
      to such children.
    </p>
  </div>

  <div #privacy_usa_section_2>
    <p class="mt-4 text-lg font-bold">2. Categories of Information We Collect</p>
    <p>We collect the following categories of personal data:</p>

    <p>a) Personal Identification Information</p>
    <ul>
      <li>Name, address, email, phone number, and date of birth.</li>
      <li>Social Security Numbers (SSNs), financial account numbers, and other financial details.</li>
    </ul>
    <p>b) Employment and Financial Information</p>
    <ul>
      <li>Salary and payroll information.</li>
      <li>Employment status, job title, and employer details.</li>
      <li>Bank account information for payments.</li>
      <li>Nonpublic Personal Information (NPI), as defined by the GLBA, including financial transaction details.</li>
    </ul>
    <p>c) Technical Data</p>
    <ul>
      <li>IP address, browser type, device and operating system information, time zone, and geolocation data.</li>
      <li>Cookies, page interactions, login information, session times, and tracking data to monitor usage and improve user experience.</li>
    </ul>
    <p>d) More about Cookies</p>
    <p>
      Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you
      visit our websites, we may collect information from you automatically through cookies or similar technology.
    </p>
    <p>For further information, visit <a href="https://www.allaboutcookies.org">allaboutcookies.org</a>.</p>
    <p>Paidiem uses cookies in a range of ways to improve your experience on our website, including:</p>
    <ul>
      <li>Name, address, email, phone number, and date of birth.</li>
      <li>Social Security Numbers (SSNs), financial account numbers, and other financial details.</li>
    </ul>
    <p>There are a number of different types of cookies, however, our website uses:</p>
    <ul>
      <li>
        <b>Functionality</b> - Paidiem uses these cookies so that we recognize you on our website and remember your previously selected
        preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are
        used.
      </li>
      <li>
        <b>Analytics</b> - Paidiem uses these cookies to collect information about your visit to our website, the content you viewed, the
        links you followed and information about your browser, device, and your IP address.
      </li>
      <li><b>Tracking</b> - These enable us to track use of services and content on the platform.</li>
    </ul>
    <p class="font-bold">Managing cookies:</p>
    <p>
      You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a
      few cases, some of our website features may not function as a result.
    </p>
  </div>

  <div #privacy_usa_section_3>
    <p class="mt-4 text-lg font-bold">3. We collect your data when:</p>
    <ul>
      <li>You register for an account online or utilize any of our products or services.</li>
      <li>You voluntarily complete a survey or provide feedback via email.</li>
      <li>You use or view our website via your browser's cookies.</li>
      <li>Your employer provides or verifies your name, contact information, financial information and transaction data.</li>
    </ul>
    <p>
      We do not collect biometric data or health information. If additional sensitive data is collected in the future, we will update this
      Privacy Policy accordingly.
    </p>
  </div>

  <div #privacy_usa_section_4>
    <p class="mt-4 text-lg font-bold">3. How We Use Your Information</p>
    <p>We use your information for the following purposes:</p>
    <ul>
      <li>
        Providing services: We process your transactions (payroll, earned wage access, and other services requested by you or your employer)
        and manage your account.
      </li>
      <li>
        Marketing and Communication: We send updates, offers, or relevant content regarding Paidiem services, as well as information on
        other products and services that you might like (subject to opt-out preferences).
      </li>
      <li>Security: We send messages and codes to your phone for authentication purposes.</li>
      <li>Compliance: To comply with legal obligations, including tax regulations and financial reporting.</li>
      <li>Improvement of Services: To analyze usage and improve the performance of our Website and services.</li>
      <li>Fraud Prevention and Security: To protect against fraud, unauthorized access, or legal violations.</li>
    </ul>
    <p>
      We store your data utilizing industry best practices. Our infrastructure is designed to provide robust security measures, including
      encryption, access controls, and regular audits. We partner with reputable cloud service providers that have a proven track record in
      maintaining stringent security protocols to safeguard your data.
    </p>
    <p>
      Production data is stored in secure data centers. These data centers adhere to the highest industry standards for security,
      reliability, and data protection, including compliance with applicable regulations.
    </p>
    <p>
      In the event of actual or suspected data breach, applicable regulators will be informed and procedures adhered to. If you suspect your
      data has been assessed by unauthorized persons, please contact us immediately.
    </p>
    <p>
      Paidiem will keep user data for so long as is reasonably necessary to use it for the purposes outlined above. In general, that period
      of time extends, for regulatory reasons, for five years after you cease using our services.
    </p>
  </div>

  <div #privacy_usa_section_5>
    <p class="mt-4 text-lg font-bold">4. Disclosure of Information to Third Parties</p>
    <p>We may share personal data under the following circumstances, as needed:</p>

    <p class="font-bold">a) Affiliated Entities</p>
    <ul>
      <li>
        Data may be shared with our affiliated companies for service delivery, operational purposes, and improvement of our products and
        services.
      </li>
    </ul>
    <p class="font-bold">b) Nonaffiliated Third Parties</p>
    <ul>
      <li>Employers/Staffing Agencies: Data may be shared to facilitate payroll and employment-related processes.</li>
      <li>
        Service Providers, including IT vendors, data storage providers, and payment processors: Data may be shared to provide services.
      </li>
      <li>Legal Authorities: Data may be shared when and as required by law, subpoena, or government investigation.</li>
    </ul>
    <p>We try to ensure that these third parties adhere to strict privacy and security obligations when processing personal data.</p>
  </div>

  <div #privacy_usa_section_6>
    <p class="mt-4 text-lg font-bold">5. Categories of Information Disclosed</p>
    <p>The types of information shared with third parties include:</p>
    <ul>
      <li>Identification Data: Name, contact information, and employment details.</li>
      <li>Financial Data: Bank account numbers and payment records for payroll purposes.</li>
      <li>Technical Data: IP addresses or cookies, shared with analytics and security service providers.</li>
    </ul>
    <p>
      We do not sell personal data to third parties. Should our data-sharing practices change, we will notify you and provide an option to
      opt out of any future data sales.
    </p>
  </div>

  <div #privacy_usa_section_7>
    <p class="mt-4 text-lg font-bold">6. Your Rights and Opt-Out Options</p>
    <p>You have the right to control how your personal data is used.</p>
    <p>
      In general, Paidiem would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the
      following:
    </p>
    <ul>
      <li>
        <b>The right to be informed</b> - You have the right to be informed about what personal data Paidiem collects, and the intended use
        of that data.
      </li>
      <li>
        <b>The right to access</b> - You have the right to request Paidiem for copies of your personal data. Upon receipt of your access
        request, Paidiem will provide a response within 30 days.
      </li>
      <li>
        <b>The right to rectification</b> - You have the right to request that Paidiem correct any information you believe is inaccurate.
        You also have the right to request Paidiem to complete the information you believe is incomplete.
      </li>
      <li><b>The right to erasure</b> - You have the right to request that Paidiem erase your personal data, under certain conditions.</li>
      <li>
        <b>The right to restrict processing</b> - You have the right to request that Paidiem restrict the processing of your personal data,
        under certain conditions.
      </li>
      <li>
        <b>The right to object to processing</b> - You have the right to object to Paidiem's processing of your personal data, under certain
        conditions.
      </li>
      <li>
        <b>The right to data portability</b> - You have the right to request that Paidiem transfer the data that we have collected to
        another organization, or directly to you, under certain conditions.
      </li>
    </ul>
    <p>
      If you would like to exercise any of these rights, please contact us at our email:
      <a href="mailTo:support@paidiem.com">support&#64;paidiem.com</a> or call us at: +1 416-907-2002 If you make a request, we have one
      month to respond to you.
    </p>
    <p class="mt-4 text-lg font-bold">Opt-Out options:</p>
    <p class="font-bold">a) Marketing Communications</p>
    <ul>
      <li>You may opt out of receiving marketing emails by following the "unsubscribe" link or contacting us directly.</li>
    </ul>
    <p class="font-bold">b) Data Sharing Opt-Out</p>
    <ul>
      <li>
        You may object to the sharing of your personal data for purposes beyond service delivery. If so, contact us directly and we will
        prevent sharing of your personal information with any third party beyond those essential to providing the services that you request.
      </li>
    </ul>
    <p class="font-bold">c) Exercising Your Rights</p>
    <ul>
      <li>
        To exercise your rights, contact us at <a href="mailTo:support@paidiem.com">Paidiem Support</a>. Requests will be processed within
        30 days.
      </li>
    </ul>
    <p class="font-bold">d) Opt-Out Validity</p>
    <ul>
      <li>Opt-out directions for data sharing will remain valid for five years unless you revoke the request.</li>
    </ul>
  </div>

  <div #privacy_usa_section_8>
    <p class="mt-4 text-lg font-bold">7. Protection of Nonpublic Personal Information (NPI)</p>
    <p>We take the confidentiality and security of NPI seriously and implement safeguards, including:</p>
    <ul>
      <li>Encryption of sensitive financial data.</li>
      <li>Access Controls to limit who can view personal information.</li>
      <li>Regular Audits to ensure compliance with U.S. financial data security regulations.</li>
    </ul>
    <p>NPI shared with third parties (e.g., payment processors) is protected by contractual obligations to maintain confidentiality.</p>
  </div>

  <div #privacy_usa_section_9>
    <p class="mt-4 text-lg font-bold">8. Governing Law</p>
    <p>
      This Privacy Policy is governed by and interpreted under the laws of Delaware. Any disputes will be subject to the jurisdiction of
      Delaware, unless otherwise required by applicable law.
    </p>
  </div>

  <div #privacy_usa_section_10>
    <p class="mt-4 text-lg font-bold">9. Changes to This Privacy Policy</p>
    <p>We may update this Privacy Policy periodically. When we make changes, we will notify users through:</p>
    <ul>
      <li>An email notice to registered users.</li>
      <li>A prominent notice on the Website or App.</li>
      <li>Users will be required to accept policy changes to continue using the service.</li>
    </ul>
    <p>We encourage you to review this policy regularly for updates.</p>
  </div>

  <div #privacy_usa_section_11>
    <p class="mt-4 text-lg font-bold">10. Delivery of Privacy Notices</p>
    <p>Users are informed of this Privacy Policy:</p>
    <ul>
      <li>At the time of data collection (e.g., when creating an account or accessing services).</li>
      <li>Through notifications provided by employers or staffing agencies when data is collected indirectly.</li>
      <li>By receiving updates within the Platform when the policy changes.</li>
    </ul>
  </div>

  <div #privacy_usa_section_12>
    <p class="mt-4 text-lg font-bold">11. Contact Information</p>
    <p>If you have any questions or wish to exercise your rights under this Privacy Policy, contact us at:</p>
    <p>Email: <a href="mailTo:support@paidiem.com">support&#64;paidiem.com</a></p>
    <p>Phone: +1 416-907-2002</p>
    <p>Mail: Paidiem Technologies Inc. 251 Little Falls Drive Wilmington, DE 19808</p>
  </div>

  <div #privacy_usa_section_13>
    <p class="mt-4 text-lg font-bold">12. Summary of Notice Requirements</p>
    <p>We provide notice to the following categories of data subjects:</p>
    <ul>
      <li>Website and App Users: Through this Privacy Policy displayed on our Website and App.</li>
      <li>Indirect Data Subjects: Via employer or partner notifications.</li>
      <li>Changes: By proactive notification of policy changes.</li>
    </ul>
    <p>
      This Privacy Policy reflects Paidiem's commitment to protecting your personal data and complying with U.S. privacy standards. For any
      questions or further assistance, please contact us using the information above.
    </p>
  </div>

  <p>
    <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_usa_container_top)"
      >Return to Top</a
    >
  </p>
</div>
