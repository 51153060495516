<div>
  <div #privacy_container_top></div>
  <div>
    <p>
      Paidiem is committed to protecting user's Personal Data and respecting their privacy. This privacy policy will explain how our
      organization uses the personal data we collect from you when you use our platform (Website and/or App). Additionally it is intended to
      inform users about their privacy rights and how the law protects them.
    </p>
    <p>
      <span>Topics:</span>
    </p>
    <ul>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_section_1)"
          >What data do we collect?</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_section_2)"
          >How do we collect your data?</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_section_3)"
          >How will we use your data?</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_section_4)"
          >How do we store your data?</a
        >
      </li>
      <li><a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_section_5)">Marketing</a></li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_section_6)"
          >What are your data protection rights?</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_section_7)"
          >What are cookies?</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_section_8)"
          >How do we use cookies?</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_section_9)"
          >What types of cookies do we use?</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_section_10)"
          >How to manage your cookies</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_section_11)"
          >Privacy policies of other websites</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_section_12)"
          >Changes to our privacy policy</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_section_13)"
          >How to contact us</a
        >
      </li>
    </ul>
  </div>

  <div #privacy_section_1>
    <p class="mt-4 text-lg font-bold">What data do we collect?</p>
    <p data-cy="textPrivacyDataCollect">Paidiem collects the following data:</p>
    <ul>
      <li>Personal identification information: (Name, email address, phone number, birthdate, etc.)</li>
      <li>Transaction data: Records of transactions performed by users on the Paidiem platform.</li>
      <li>
        Employment data: Information about user's employment status and contract information with the employer they are utilizing Paidiem
        services with.
      </li>
      <li>
        Technical data: Pertaining to website or app use. Such information as IP address, time-zone, browser-type, device and operating
        system types, location, page interactions, login information, and session times.
      </li>
    </ul>
  </div>

  <div #privacy_section_2>
    <p class="mt-4 text-lg font-bold">How do we collect your data?</p>
    <p>You directly provide Paidiem with most of the data we collect. We collect data and process data when you:</p>
    <ul>
      <li>Register online or utilize any of our products or services.</li>
      <li>Voluntarily complete a customer survey or provide feedback via email.</li>
      <li>Use or view our website via your browser's cookies.</li>
    </ul>
    <p>Paidiem may also receive user data indirectly from the following sources:</p>
    <ul>
      <li>
        Employers that users are utilizing the Paidiem services with. This may include identity, contact, financial and transaction data.
      </li>
    </ul>
  </div>

  <div #privacy_section_3>
    <p class="mt-4 text-lg font-bold">How will we use your data?</p>
    <p>
      Paidiem does not sell user data to 3rd parties. However, personal information may be shared with select parties (partners and
      employers) for the fulfilment of any contract we enter into with users and their employer.
    </p>
    <p>Paidiem collects user data so that we can:</p>
    <ul>
      <li>Process your transactions, service usage and manage your account.</li>
      <li>Email you with communications regarding Paidiem services (i.e. updates, confirmations).</li>
      <li>Email you with information on other products and services we think you might like.</li>
      <li>
        Send sms messages to your phone number, in order to provide one-time-codes for authentication or multi-factor-authentication
        purposes.
      </li>
      <li>Optimize our platform to improve it and report aggregated management information.</li>
      <li>Improve our services.</li>
      <li>Track issues that may occur.</li>
      <li>Enable access to third party products and services.</li>
    </ul>
  </div>

  <div #privacy_section_4>
    <p class="mt-4 text-lg font-bold">How and Where do we store your data?</p>
    <p>
      Paidiem securely stores your data, utilizing industry best practices. Our infrastructure is designed to provide robust security
      measures, including encryption, access controls, and regular audits. We partner with reputable cloud service providers that have a
      proven track record in maintaining stringent security protocols to safeguard your data.
    </p>
    <p>
      Production data is stored in secure data centers located in Canada. These data centers adhere to the highest industry standards for
      security, reliability, and data protection, including compliance with applicable regulations.
    </p>
    <p>
      In the event of actual or suspected Personal Data Breach, applicable regulators will be informed and procedures adhered to. If you
      suspect your data has been assessed by unauthorized persons, contact us immediately.
    </p>
    <p>
      Paidiem will keep user data for so long as is reasonably necessary to use it for the purposes outlined above (<span
        >How we use your data</span
      >). Generally for as long as they are using Paidiem, and for 5 years after, in order to comply with regulatory law.
    </p>
    <p>Longer periods of retention may be needed under certain circumstances for legal reasons (i.e. cases of fraud).</p>
  </div>

  <div>
    <p class="mt-4 text-lg font-bold">Notice on children</p>
    <p>
      The Paidiem platform and services are not intended for individuals below 16 years of age and we do not knowingly collect data related
      to such children.
    </p>
  </div>

  <div #privacy_section_5>
    <p class="mt-4 text-lg font-bold">Marketing</p>
    <p>
      Paidiem would like to send you information about products and services of ours that we think you might like, as well as those of our
      partner companies.
    </p>
    <p>If you have agreed to receive marketing, you may always opt out at a later date</p>
    <p>You have the right at any time to stop Paidiem from contacting you for marketing purposes.</p>
  </div>

  <div #privacy_section_6>
    <p class="mt-4 text-lg font-bold">What are your data protection rights?</p>
    <p>
      Paidiem would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
    </p>
    <p>
      <span>The right to be informed </span> - You have the right to be informed about what personal data of yours Paidiem collects, and the
      intended use of that data.
    </p>
    <p>
      <span>The right to access</span> - You have the right to request Paidiem for copies of your personal data. Upon receipt of your access
      request, Paidiem will provide a response within 30 days.
    </p>
    <p>
      <span>The right to rectification</span> - You have the right to request that Paidiem correct any information you believe is
      inaccurate. You also have the right to request Paidiem to complete the information you believe is incomplete.
    </p>
    <p>
      <span>The right to erasure</span> - You have the right to request that Paidiem erase your personal data, under certain conditions.
    </p>
    <p>
      <span>The right to restrict processing</span> - You have the right to request that Paidiem restrict the processing of your personal
      data, under certain conditions.
    </p>
    <p>
      <span>The right to object to processing</span> - You have the right to object to Paidiem's processing of your personal data, under
      certain conditions.
    </p>
    <p>
      <span>The right to data portability</span> - You have the right to request that Paidiem transfer the data that we have collected to
      another organization, or directly to you, under certain conditions.
    </p>
    <p>
      If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at
      our email:
      <a href="mailTo:support@paidiem.com">support&#64;paidiem.com</a>, through our <a href="/support">Support Page</a> or call us at: +1
      416-907-2002
    </p>
  </div>

  <div #privacy_section_7>
    <p class="mt-4 text-lg font-bold">What are cookies</p>
    <p>
      Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you
      visit our websites, we may collect information from you automatically through cookies or similar technology.
    </p>
    <p>
      For further information, visit
      <a href="https://www.allaboutcookies.org">allaboutcookies.org</a>.
    </p>
  </div>

  <div #privacy_section_8>
    <p class="mt-4 text-lg font-bold">How do we use cookies?</p>
    <p>Paidiem uses cookies in a range of ways to improve your experience on our website, including:</p>
    <ul>
      <li>Keeping you signed in</li>
      <li>Ensuring authorisation for performing certain actions on our platform</li>
      <li>Understanding how you use our website</li>
      <li>Monitoring performance of our platform</li>
    </ul>
  </div>

  <div #privacy_section_9>
    <p class="mt-4 text-lg font-bold">What types of cookies do we use?</p>
    <p>There are a number of different types of cookies, however, our website uses:</p>
    <ul>
      <li>
        Functionality - Paidiem uses these cookies so that we recognize you on our website and remember your previously selected
        preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are
        used.
      </li>
      <li>
        Analytics - Paidiem uses these cookies to collect information about your visit to our website, the content you viewed, the links you
        followed and information about your browser, device, and your IP address.
      </li>
      <li>Tracking - These enable us to track use of services and content on the platform.</li>
    </ul>
  </div>

  <div #privacy_section_10>
    <p class="mt-4 text-lg font-bold">How to manage cookies</p>
    <p>
      You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a
      few cases, some of our website features may not function as a result.
    </p>
  </div>

  <div #privacy_section_11>
    <p class="mt-4 text-lg font-bold">Privacy policies of other websites</p>
    <p>
      The Paidiem website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to
      another website, you should read their privacy policy.
    </p>
  </div>

  <div #privacy_section_12>
    <p class="mt-4 text-lg font-bold">Changes to our privacy policy</p>
    <p>
      Paidiem keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on
      12th August 2024.
    </p>
  </div>

  <div #privacy_section_13>
    <p class="mt-4 text-lg font-bold">How to contact us</p>
    <p>
      If you have any questions about Paidiem's privacy policy, the data we hold on you, or you would like to exercise one of your data
      protection rights, please do not hesitate to contact us.
    </p>
    <p>Email us at: <a href="mailTo:support@paidiem.com">support&#64;paidiem.com</a></p>
    <p>Call us: +1 416-907-2002</p>
    <p>
      For Canadian residents: If you have any concerns with how Paidiem has handled your data, you may escalate to the Privacy Commissioner
      of Canada. <a href="https://www.priv.gc.ca/en">https://www.priv.gc.ca/en</a>
    </p>
  </div>
  <p>
    <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(privacy_container_top)">Return to Top</a>
  </p>
</div>
