import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pd-terms-and-conditions',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './terms-and-conditions.component.html',
  styles: [
    ':host { display: block; overflow: auto; h5 { @apply text-lg font-bold } h6 { @apply text-base font-bold } p { @apply mb-4 } }',
  ],
})
export class TermsAndConditionsComponent {}
