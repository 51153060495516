<span *ngIf="policyType === 'terms'" class="inline-block">
  <a href="javascript:void(0)" class="{{ linkStyleClasses }}" (click)="openTermsModal()">{{ linkCustomText ?? 'Terms & Conditions' }}</a>
</span>
<ng-template #dialogTemplateTerms>
  <pd-header [closable]="false">Terms & Conditions</pd-header>
  <div mat-dialog-content>
    <pd-terms-and-conditions></pd-terms-and-conditions>
  </div>
  <div mat-dialog-actions class="flex justify-end">
    <button pd-button type="button" color="secondary" mat-dialog-close>Close</button>
  </div>
</ng-template>
<ng-template #dialogTemplateTermsUsa>
  <pd-header [closable]="false">Terms & Conditions US</pd-header>
  <div mat-dialog-content>
    <pd-terms-and-conditions-usa></pd-terms-and-conditions-usa>
  </div>
  <div mat-dialog-actions class="flex justify-end">
    <button pd-button type="button" color="secondary" mat-dialog-close>Close</button>
  </div>
</ng-template>

<span *ngIf="policyType === 'privacy'" class="inline-block">
  <a href="javascript:void(0)" class="{{ linkStyleClasses }}" (click)="openPrivacyPolicyModal()">{{
    linkCustomText ?? 'Privacy Policy'
  }}</a>
</span>
<ng-template #dialogTemplatePrivacy>
  <pd-header [closable]="false">Privacy Policy</pd-header>
  <div mat-dialog-content>
    <pd-privacy-policy></pd-privacy-policy>
  </div>
  <div mat-dialog-actions class="flex justify-end">
    <button pd-button type="button" color="secondary" mat-dialog-close>Close</button>
  </div>
</ng-template>
<ng-template #dialogTemplatePrivacyUsa>
  <pd-header [closable]="false">Privacy Policy US</pd-header>
  <div mat-dialog-content>
    <pd-privacy-policy-usa></pd-privacy-policy-usa>
  </div>
  <div mat-dialog-actions class="flex justify-end">
    <button pd-button type="button" color="secondary" mat-dialog-close>Close</button>
  </div>
</ng-template>
