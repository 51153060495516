import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { CommonComponentsModule } from '@core/components/common-components.module';

import { AuthenticatedUser } from '@entities/authentication/authenticated-user.entity';
import { AuthenticatedUserService } from '@services/authenticated-user/authenticated-user.service';

import { TermsAndConditionsComponent } from '@components/terms-and-conditions/terms-and-conditions.component';
import { TermsAndConditionsUsaComponent } from '@components/terms-and-conditions/terms-and-conditions-usa.component';
import { PrivacyPolicyComponent } from '@components/privacy-policy/privacy-policy.component';
import { PrivacyPolicyUsaComponent } from '@components/privacy-policy/privacy-policy-usa.component';

import { CountryCode } from '@services/geo/geo.service';

enum policyTypes {
  terms = 'terms',
  privacy = 'privacy',
  // disclosure = 'disclosure',
}

@Component({
  selector: 'pd-policies-modal',
  standalone: true,
  imports: [
    CommonModule,
    CommonComponentsModule,
    TermsAndConditionsComponent,
    TermsAndConditionsUsaComponent,
    PrivacyPolicyComponent,
    PrivacyPolicyUsaComponent,
  ],
  templateUrl: './policies-modal.component.html',
  styles: [],
})
export class PoliciesModalComponent implements OnInit {
  @Input() public policyType: string = policyTypes.terms;
  @Input() public linkCustomText: string = null;
  @Input() public linkStyleClasses: string = null;
  @Input() public focusClass: string | boolean = false;
  @ViewChild('dialogTemplatePrivacy')
  public dialogTemplatePrivacy: TemplateRef<any>;
  @ViewChild('dialogTemplateTerms')
  public dialogTemplateTerms: TemplateRef<any>;
  @ViewChild('dialogTemplatePrivacyUsa')
  public dialogTemplatePrivacyUsa: TemplateRef<any>;
  @ViewChild('dialogTemplateTermsUsa')
  public dialogTemplateTermsUsa: TemplateRef<any>;

  public authenticatedUser = new AuthenticatedUser();
  public constructor(private authenticatedUserService: AuthenticatedUserService, private dialogService: MatDialog) {}

  public ngOnInit(): void {
    this.authenticatedUser = this.authenticatedUserService.authenticatedUser;
    if (!this.linkStyleClasses) {
      this.linkStyleClasses = `text-primary dark:text-primary-light`;
    }
  }

  public openPrivacyPolicyModal(): void {
    if (this.authenticatedUser?.countryCode === CountryCode.UnitedStatesOfAmerica) {
      this.dialogService.open(this.dialogTemplatePrivacyUsa, { autoFocus: this.focusClass });
    } else {
      this.dialogService.open(this.dialogTemplatePrivacy, { autoFocus: this.focusClass });
    }
  }

  public openTermsModal(): void {
    if (this.authenticatedUser?.countryCode === CountryCode.UnitedStatesOfAmerica) {
      this.dialogService.open(this.dialogTemplateTermsUsa, { autoFocus: this.focusClass });
    } else {
      this.dialogService.open(this.dialogTemplateTerms, { autoFocus: this.focusClass });
    }
  }
}
