<div>
  <div #terms_usa_container_top></div>
  <p data-cy="textTermsModal">
    <b>Please read these terms and conditions carefully before using Our Platform.</b>
  </p>
  <div>
    <p class="mt-4 text-lg font-bold">Paidiem Technologies Inc. USA Terms & Conditions</p>
  </div>
  <div>
    <p>
      Please read these terms and conditions ("Terms") carefully before using the Paidiem Platform. These Terms constitute a legal agreement
      between You and Paidiem Technologies Inc., and by using the Early Wage Access Services, you agree to be bound by these Terms. If you
      do not wish to be bound by these Terms, you may not access or use the Services.
      <b
        >You may not use the Services or accept these Terms if (a) You are not at least 18 years of age; (b) You are prohibited by law from
        using the Services; (c) You are working in an industry prohibited from utilizing the Services; (d) You are not a U.S. resident; or
        (e) You are a resident of any of the following states: California, Connecticut, Nevada, and New York.</b
      >
    </p>
    <p>
      <span>Contents:</span>
    </p>
    <ul>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_1)"
          >1. Interpretation</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_2)">2. Definitions</a>
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_3)"
          >3. Acknowledgments</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_4)"
          >4. Use of the Services</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_5)"
          >5. Stored Credentials and Payment Information</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_6)"
          >6. Fee Schedule</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_7)"
          >7. No Unlawful or Prohibited Use</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_8)"
          >8. Subscriptions</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_9)"
          >9. Intellectual Property</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_10)"
          >10. Your Feedback to Us</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_11)"
          >11. Links to Other Websites</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_12)"
          >12. Termination</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_13)"
          >13. Limitation of Liability</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_14)"
          >14. AS IS and AS AVAILABLE Disclaimer</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_15)"
          >15. Governing Law</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_16)"
          >16. Dispute Resolution</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_17)"
          >17. Changes to These Terms and Conditions</a
        >
      </li>
      <li>
        <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_section_18)"
          >18. Contact Us</a
        >
      </li>
    </ul>
  </div>

  <div #terms_usa_section_1>
    <p class="mt-4 text-lg font-bold">1. Interpretation</p>
    <p>
      The capitalized words below have meanings defined in Section 2. These definitions shall have the same meaning regardless of whether
      they appear in singular or in plural. The word "including" as used herein will be construed to mean "including, without limitation"
      and will not be construed to be limited to any examples provided.
    </p>
  </div>

  <div #terms_usa_section_2>
    <p class="mt-4 text-lg font-bold">2. Definitions</p>
    <p>For the purposes of interpreting these Terms:</p>
    <ul class="pl-4">
      <li>
        <b>Application</b> means the software program named <i>Paidiem</i> provided by the Company downloaded by You on any electronic
        device.
      </li>
      <li>
        <b>Affiliate</b> means an entity that controls, is controlled by or is under common control with a party, where control means
        ownership of 50% or more of the shares, equity interest or other interests entitled to vote for election of directors or other
        managing authority.
      </li>
      <li>
        <b>Company</b> (also called "<b>We</b>", "<b>Us</b>" or "<b>Our</b>" in this Agreement) refers to Paidiem Technologies Inc., whose
        address is 251 Little Falls Dr., Wilmington, DE 19808, as well as its directors, officers, employees, contractors, owners, and
        agents.
      </li>
      <li><b>Device</b> means any device that can access the Platform such as a computer, a cell phone or a digital tablet.</li>
      <li>
        <b>Early Payment</b> refers to a portion of the amount that You have earned, which is unpaid by Your employer and which is made
        available to You by Us upon Your request.
      </li>
      <li><b>Employer</b> means the company or other legal entity that employs You.</li>
      <li>
        <b>Feedback</b> means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our
        Platform.
      </li>
      <li><b>Platform</b> refers to the Application or the Website or both.</li>
      <li>
        <b>Request(s) for Early Payment</b> means a request by You for Us to process an Early Payment on the Platform, in an amount that You
        choose.
      </li>
      <li>
        <b>Services</b> refer to the Earned Wage Access (EWA) services provided by Paidiem via the Platform, which allows You to access Your
        earned by unpaid earnings via Requests for Early Payment.
      </li>
      <li><b>Subscriptions</b> refer to the services or access to the Platform that we offer on a subscription basis to You.</li>
      <li>
        <b>Terms and Conditions</b> (or "<b>Terms</b>") mean these Terms and Conditions that form the entire agreement between You and
        Company regarding the use of the Platform.
      </li>
      <li>
        <b>Third-Party Service</b> means any services or content (including data, information, banking products or services, or any other
        products or services) provided by a third-party that may be displayed, accessed, or made available by the Platform.
      </li>
      <li><b>Website</b> refers to the Paidiem website accessible at <a href="www.paidiem.com">www.paidiem.com</a>.</li>
      <li><b>You</b> means the individual accessing and/or using the Platform to use the Services.</li>
    </ul>
  </div>

  <div #terms_usa_section_3>
    <p class="mt-4 text-lg font-bold">3. Acknowledgments</p>
    <ul class="pl-4">
      <li>
        <b>3.1. </b>These are the Terms and Conditions governing the use of this Platform and the agreement that operates between You and
        the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Platform. Company
        and Employer have a separate agreement governing their relationship.
      </li>
      <li>
        <b>3.2. </b>Your access to and use of the Platform is conditioned on Your acceptance of and compliance with these Terms and
        Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Platform.
      </li>
      <li>
        <b>3.3. </b>By accessing or using the Platform You agree to be bound by these Terms and Conditions. If You disagree with any part of
        these Terms and Conditions, then You may not access the Platform.
      </li>
      <li><b>3.4. </b>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Platform.</li>
      <li>
        <b>3.5. </b>Your access to and use of the Platform is also conditioned on Your acceptance of and compliance with the Privacy Policy
        of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal
        information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please
        read Our Privacy Policy carefully before using Our Platform.
      </li>
    </ul>
  </div>

  <div #terms_usa_section_4>
    <p class="mt-4 text-lg font-bold">4. Use of the Services</p>
    <ul class="pl-4">
      <li>
        <p><b>4.1. </b>Your Responsibilities and Your Information</p>
        <p>
          With our Services, You may, at your discretion, access a portion of your earned but unpaid earnings prior to the Employer's
          payroll processing date. To make a Request for Early Payment, you must select a destination for where to send your Early Payment.
          The funds provided in an Early Payment represent wages you have earned. You are not required to repay these funds.
        </p>
        <p>
          You are solely responsible for inputting and updating the correct information, including, without limitation, Your name (personal
          or business), Your email, Your phone number, Your Social Security number (if applicable), Your current status with tax authorities
          (if applicable), Your net and gross pay (if applicable), Your banking details, and any other data provided by or on behalf of
          Employer. You represent that you have the right. If you need help changing or updating your information, please email us at
          <a href="mailTo:support@paidiem.com">support&#64;paidiem.com</a>
        </p>
        <p>
          You acknowledge and agree that you have not assigned, transferred or conveyed your wages from Employer or any part thereof. We
          have no right to assert a claim against You with respect to your earnings. If We are unable to settle an Early Payment
          transaction, your access to the Services may be limited or suspended until such time that we are able to settle the outstanding
          repayment amount.
        </p>
        <p>
          We may use your information in aggregated and/or anonymized format in connection with (i) promoting or marketing the Services, or
          (ii) other services we provide to customers.
        </p>
      </li>
      <li>
        <p><b>4.2. </b>Service Cancellation</p>
        <p>
          We reserve the right to terminate these Terms or access to the Services or Platform for any reason and at any time with or without
          notice to You. Possible reasons include:
        </p>
        <ul class="pl-4">
          <li>Service downtime</li>
          <li>Errors in the information You provide</li>
          <li>Your misuse of the Platform or Services</li>
          <li>Potential for fraud, breach of applicable law, or unauthorized transactions</li>
        </ul>
        <p>
          We cannot and do not guarantee the accuracy or completeness of any information, including specifications, availability, and any
          services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time
          without prior notice.
        </p>
        <p>
          If you choose to cancel the Services, you may do so at any time. Simply use the Platform to let us know that you are cancelling
          the Services, or email <a href="mailTo:support@paidiem.com">support&#64;paidiem.com</a>
        </p>
      </li>
    </ul>
  </div>

  <div #terms_usa_section_5 class="card_collection">
    <p class="mt-4 text-lg font-bold">5. Stored Credentials and Payment Information</p>
    <ul class="pl-4">
      <li>
        <p><b>5.1. </b>Purpose of Collecting Debit Card Information</p>
        <p>
          By providing Your debit card information, You authorize Paidiem to store it securely for facilitating instant payments. Your debit
          card will never be used to debit payments without Your explicit consent.
        </p>
      </li>
      <li>
        <p><b>5.2. </b>Authorization and Agreement</p>
        <ul class="pb-4 pl-4">
          <li>
            <b>(a) </b>Consent to Store: You consent to Paidiem securely storing your debit card information for payment purposes only.
          </li>
          <li>
            <b>(b) </b>Use of Information: The stored debit card information will only be used to facilitate credits to Your account, not
            debits.
          </li>
          <li>
            <b>(c) </b>Security Compliance: We comply with the Payment Card Industry Data Security Standard (PCI DSS) and will not share
            Your debit card information with third parties, except to facilitate payments or as required by law.
          </li>
          <li>
            <b>(d) </b>Updates or Removal: You can update or request the removal of your stored debit card by contacting
            <a href="mailTo:support@paidiem.com">support&#64;paidiem.com</a>
          </li>
          <li>
            <b>(e) </b>Acknowledgment: By providing your debit card information , You confirm that You have the legal right to use it for
            these purposes.
          </li>
        </ul>
        <p>
          If stored credentials are used for scheduled or recurring transactions, You will be informed of the transaction dates and amounts.
          All transactions will be conducted in U.S. Dollars unless otherwise specified.
        </p>
      </li>
    </ul>
  </div>

  <div #terms_usa_section_6>
    <p class="mt-4 text-lg font-bold">6. Fee Schedule</p>
    <ul class="pl-4">
      <li>
        <p><b>6.1. </b>The Company charges the following fees for its Services:</p>
        <div class="p-4">
          <table class="border-2 border-solid border-gray-500">
            <tr>
              <th class="border-2 border-solid border-gray-500 px-4">Module</th>
              <th class="border-2 border-solid border-gray-500 px-4">Fee</th>
            </tr>
            <tr>
              <td class="px-4">Earned Wage Access Payments</td>
            </tr>
            <tr>
              <td class="border-2 border-solid border-gray-500 px-4">Access to Paidiem's earned wage access</td>
              <td class="border-2 border-solid border-gray-500 px-4">Free</td>
            </tr>
            <tr>
              <td class="border-2 border-solid border-gray-500 px-4">Instant payment (received within 2 hours)</td>
              <td class="border-2 border-solid border-gray-500 px-4">0 - $5</td>
            </tr>
            <tr>
              <td class="border-2 border-solid border-gray-500 px-4">Next day payment (received within 1 business day)</td>
              <td class="border-2 border-solid border-gray-500 px-4">Free</td>
            </tr>
          </table>
        </div>
        <ul class="pb-4 pl-4">
          <li>
            <b>(a) </b>These fees may be revised by the Company in the event of any occurrence affecting Early Payment caused by government
            action, variation in taxation, change in banking fees, higher foreign exchange costs and any other matter beyond the control of
            the Company. In that event, You will have the right to cancel any pending Request for Early Payment.
          </li>
          <li>
            <b>(b) </b>No fees will be deducted by the Company in those states where such fees are prohibited, including New York,
            California, Massachusetts, Texas, and Connecticut.
          </li>
        </ul>
      </li>
      <li>
        <p><b>6.2. </b>Authorization for Payment</p>
        <p>
          You authorize the Company to deduct any fees for our Services from any Early Payments provided to you. In the event We provide to
          you an Early Payment that is in excess of your unpaid earnings, you authorize the excess to be deducted from your next paycheck.
          You may withdraw this authorization at any time by contacting us at
          <a href="mailTo:support@paidiem.com">support&#64;paidiem.com</a>.
        </p>
      </li>
      <li class="refunds">
        <p><b>6.3. </b>Refunds</p>
        <p>We may make refunds in the following situations:</p>
        <ul class="list-disc pl-4">
          <li>Transactions made in error or due to system malfunction.</li>
          <li>Unauthorized or duplicate transactions.</li>
        </ul>
        <p class="pt-4">
          Refund requests must be submitted to <a href="mailTo:support@paidiem.com">support&#64;paidiem.com</a> within 30 days of the
          transaction.
        </p>
        <p class="pb-4">Refunds will be processed within 10 business days of approval.</p>
      </li>
    </ul>
  </div>

  <div #terms_usa_section_7>
    <p class="mt-4 text-lg font-bold">7. No Unlawful or Prohibited Use</p>
    <p>
      As a condition of your use of the Services, you represent and warrant that You will not use the Services for any purpose that is
      unlawful or prohibited by these Terms. Specifically, You agree that You will not:
    </p>
    <ul>
      <li>Make Requests for Early Payment for earnings in which you do not have complete right, title and interest;</li>
      <li>Use the Services in any manner that could damage, disable, overburden or impair the Services or Platform;</li>
      <li>Access the Services other than through the Platform;</li>
      <li>Use or attempt to use any engine, software, tool, agent, or other device to navigate the Services or Platform;</li>
      <li>Attempt to decipher, decompile, disassemble or reverse-engineer any of the software comprising the Platform;</li>
      <li>Engage in any illegal activities or fraudulent use of the Services;</li>
      <li>Use the Platform for purposes not intended by Paidiem, including money laundering or unauthorized transfers;</li>
      <li>Access the Platform from jurisdictions where our services are prohibited.</li>
    </ul>
    <p>
      If We, in our sole discretion, believe you may have engaged in any activities restricted by these Terms or by law, we may take various
      actions to protect Paidiem, other users, or other third parties from fees, fines, penalties or any other liability. The actions we
      take may include the following:
    </p>
    <ul>
      <li>Closing, suspending, or limiting Your access to your account or ability to use the Services;</li>
      <li>Updating inaccurate information You previously provided to Us;</li>
      <li>Refusing to allow You to use the Services in the future;</li>
      <li>Holding you liable for the amount of damages to Paidiem caused by Your violation of these Terms.</li>
    </ul>
  </div>

  <div #terms_usa_section_8>
    <p class="mt-4 text-lg font-bold">8. Subscriptions</p>
    <ul class="pl-4">
      <li>
        <p><b>8.1. </b>Subscription period</p>
        <ul class="pb-4 pl-4">
          <li>
            <b>(a) </b>The Platform or some features of the Platform are available only with a paid Subscription. Employer will be billed in
            advance on a recurring and periodic basis (such as daily, weekly, monthly or annually), depending on the type of Subscription
            plan Employer selects.
          </li>
          <li>
            <b>(b) </b>At the end of each period, the Subscription will automatically renew under the exact same conditions unless Company
            cancels it.
          </li>
        </ul>
      </li>
      <li>
        <p><b>8.2. </b>Subscription cancellations</p>
        <p>
          Employer may cancel the Subscription renewal either through its account settings page or by contacting the Company. Company will
          not receive a refund for the fees already paid for its current Subscription period. You will be able to access the Platform until
          the end of Employer's current Subscription period.
        </p>
      </li>
      <li>
        <p><b>8.3. </b>Fee Changes</p>
        <ul class="pb-4 pl-4">
          <li>
            <b>(a) </b>The Company, in its sole discretion and at any time, may modify the Subscription fees. Any Subscription fee change
            will become effective at the end of the then-current Subscription period.
          </li>
          <li><b>(b) </b>The Company will provide Employer with reasonable prior notice of any change in Subscription fees.</li>
        </ul>
      </li>
      <li>
        <p><b>8.4. </b>Subscription Refunds</p>
        <ul class="pb-4 pl-4">
          <li><b>(a) </b>Except when required by law, paid Subscription fees are non-refundable.</li>
          <li>
            <b>(b) </b>Certain refund requests for Subscriptions may be considered by the Company on a case-by-case basis and granted at the
            sole discretion of the Company.
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <div #terms_usa_section_9>
    <p class="mt-4 text-lg font-bold">9. Intellectual Property</p>
    <ul class="pl-4">
      <li>
        <b>9.1. </b>The Platform and its original content (excluding Content provided by You or other users), features and functionality are
        and will remain the exclusive property of the Company and its licensors.
      </li>
      <li>
        <b>9.2. </b>The Platform is protected by intellectual property laws and all rights as between yourself and the Company only exist
        for purposes of your use of the Platform.
      </li>
      <li>
        <b>9.3. </b>Our trademarks and trade dress may not be used in connection with any product or service without the prior written
        consent of the Company.
      </li>
    </ul>
  </div>

  <div #terms_usa_section_10>
    <p class="mt-4 text-lg font-bold">10. Your Feedback to Us</p>
    <p>
      You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective,
      You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce,
      disclose, sub-license, distribute, modify and exploit such Feedback without restriction.
    </p>
  </div>

  <div #terms_usa_section_11>
    <p class="mt-4 text-lg font-bold">11. Links to Other Websites</p>
    <ul class="pl-4">
      <li>
        <b>11.1. </b>Our Platform may contain links to third-party web sites or services that are not owned or controlled by the Company.
      </li>
      <li>
        <b>11.2. </b>Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any
        third-party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly
        or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such
        content, goods or services available on or through any such web sites or services.
      </li>
      <li>
        <b>11.3. </b>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services
        that You visit.
      </li>
    </ul>
  </div>

  <div #terms_usa_section_12>
    <p class="mt-4 text-lg font-bold">12. Termination</p>
    <p>
      We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without
      limitation if You breach these Terms and Conditions. Upon termination, Your right to use the Platform will cease immediately.
      Otherwise, these Terms are terminated when You cease to use the Services.
    </p>
    <p>
      You may terminate the Services at any time with or without explanation. If You terminate your account, all data will be deleted in
      compliance with our Privacy Policy.
    </p>
  </div>

  <div #terms_usa_section_13>
    <p class="mt-4 text-lg font-bold">13. Limitation of Liability</p>
    <ul class="pl-4">
      <li>
        <b>13.1. </b>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its affiliates under
        any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the higher of (a) the amount
        actually paid by You through the Platform or (b) USD $100.
      </li>
      <li>
        <b>13.2. </b>To the maximum extent permitted by applicable law, in no event shall the Company or its affiliates be liable for any
        special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss
        of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related
        to the use of or inability to use the Platform, third-party software and/or third-party hardware used with the Platform, or
        otherwise in connection with any provision of this Terms), even if Company or any affiliate has been advised of the possibility of
        such damages and even if the remedy fails of its essential purpose.
      </li>
      <li>
        <b>13.3. </b>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential
        damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to
        the greatest extent permitted by law.
      </li>
    </ul>
  </div>

  <div #terms_usa_section_14>
    <p class="mt-4 text-lg font-bold">14. AS IS and AS AVAILABLE Disclaimer</p>
    <ul class="pl-4">
      <li>
        <b>14.1. </b>The Platform is provided to You AS IS and AS AVAILABLE and with all faults and defects without warranty of any kind. To
        the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their
        respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise,
        with respect to the Platform, including all implied warranties of merchantability, fitness for a particular purpose, title and
        non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without
        limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the
        Platform will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications,
        systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors
        or defects can or will be corrected.
      </li>
      <li>
        <b>14.2. </b>Without limiting the foregoing, neither the Company nor any of its partners makes any representation or warranty of any
        kind, express or implied: (i) as to the operation or availability of the Platform, or the information, content, and materials or
        products included thereon; (ii) that the Platform will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or
        currency of any information or content provided through the Platform; or (iv) that the Platform, its servers, the content, or
        e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other
        harmful components.
      </li>
      <li>
        <b>14.3. </b>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory
        rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions
        and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.
      </li>
    </ul>
  </div>

  <div #terms_usa_section_15>
    <p class="mt-4 text-lg font-bold">15. Governing Law</p>
    <p>
      The laws of the State of Delaware, excluding its conflicts of law rules, shall govern this Terms and Your use of the Platform. Your
      use of the Application may also be subject to other local, state, national, or international laws.
    </p>
  </div>

  <div #terms_usa_section_16>
    <p class="mt-4 text-lg font-bold">16. Dispute Resolution</p>
    <p>
      If You have any concern or dispute about the Platform, You agree to first try to resolve the dispute informally by contacting the
      Company at <a href="mailTo:support@paidiem.com">support&#64;paidiem.com</a>. We will investigate disputes and provide a response
      within 10 business days. If a resolution cannot be reached, the matter may be escalated to arbitration in accordance with the
      governing law.
    </p>
  </div>

  <div #terms_usa_section_17>
    <p class="mt-4 text-lg font-bold">17. Changes to These Terms and Conditions</p>
    <ul class="pl-4">
      <li><b>17.. </b></li>
      <li>
        <b>17.1. </b>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material
        We will make reasonable efforts to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material
        change will be determined at Our sole discretion. You will be notified of changes via email, in-app notifications, and/or
        announcements on the Website.
      </li>
      <li>
        <b>17.2. </b>By continuing to access or use Our Platform after those revisions become effective, You agree to be bound by the
        revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Platform.
      </li>
      <li>
        <b>17.3. </b>Users may opt out of marketing communications or specific services by contacting
        <a href="mailTo:support@paidiem.com">support&#64;paidiem.com</a>
      </li>
    </ul>
  </div>

  <div #terms_usa_section_18>
    <p class="mt-4 text-lg font-bold">18. Contact Us</p>
    <p>If you have any questions about these Terms and Conditions or the Services, You can contact us by the following means:</p>
    <p>Email: <a href="mailTo:support@paidiem.com">support&#64;paidiem.com</a></p>
    <p>Phone: +1 416-907-2002</p>
    <p>Mail: Paidiem Technologies Inc. 251 Little Falls Drive Wilmington, DE 19808</p>
  </div>

  <p>
    <a href="javascript:void(0)" class="text-primary dark:text-primary-light" (click)="scrollTo(terms_usa_container_top)">Return to Top</a>
  </p>
</div>
